import { render, staticRenderFns } from "./TopicList.vue?vue&type=template&id=62472124&scoped=true&"
import script from "./TopicList.vue?vue&type=script&lang=js&"
export * from "./TopicList.vue?vue&type=script&lang=js&"
import style0 from "./TopicList.vue?vue&type=style&index=0&id=62472124&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62472124",
  null
  
)

export default component.exports