<template>
    <div class="topicList">
        <div class="topicItem" v-for="(item,index) in topicList" :key="index" @click="tagJump(item)">
            <div class="topicInfo">
                <div class="portrait">
                    <ImgDecypt class="avatarImg" :src="item.coverImg" />
                </div>
                <div class="topicLabel">
                    <div class="topicTitle">
                        <span>#{{item.name}}</span>
                    </div>
                    <div class="topicDtails">
                        <span>{{item.tagDesc}}</span>
                    </div>
                </div>
            </div>
            <div class="btn" @click.stop="focus(item)">
                <div class="focusBtn" v-if="!item.hasCollected">
                    <svg-icon icon-class="btnAdd" class="btnIcon"></svg-icon>
                 <span>
                    关注
                </span>
                </div>
                <div class="alreadyFocus" v-else>
                <span>
                    已关注
                </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImgDecypt from "@/components/ImgDecypt";
import {collectTag} from "@/api/community";

export default {
  name: "TopicList",
    components:{
        ImgDecypt
    },
    props:{
        topicList:{
            type: Array
        }
    },methods:{
        async focus(item) {
            /**
             * 关注/取消关注 话题
             */
            let req = {
                isCollect: !item.hasCollected,
                objID: item.id,
                type: "tag"
            }
            let res = await this.$Api(collectTag, req);
    
            if(res && res.code == 200){
                if(!item.hasCollected){
                    item.hasCollected = true;
                    this.$toast({
                        message: '关注成功',
                        position: 'top',
                    });
                }else{
                    item.hasCollected = false;
                    this.$toast({
                        message: '取消关注',
                        position: 'top',
                    });
                }
            }
        },tagJump(item){
            /**
             * 话题详情
             */
            this.$router.push({
                path:"/topicDetails",
                query:{
                    id:item.id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .topicList{
        .topicItem{
            display: flex;
            justify-content: space-between;
            align-items: end;
            padding: 0 12px;
            margin-top: 12px;

            .topicInfo{
                display: flex;
                align-items: center;

                .portrait{
                    width: 60px;
                    height: 60px;
                    //border-radius: 50%;
                    //background: rgb(230,230,230);
                    
                    .avatarImg{
                        border-radius: 4px;
                        
                        /deep/ .van-image__img{
                            border-radius: 4px;
                        }
                    }
                }

                .topicLabel{
                    padding-left: 12px;

                    .topicTitle{
                        font-size: 16px;
                        color: #333333;
                        font-weight: 500;
                    }

                    .topicDtails{
                        margin-top: 6px;
                        font-size: 14px;
                        color: #999999;
                    }
                }
            }


            .btn{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;

                .btnIcon{
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                }

                .focusBtn{
                    width: 60px;
                    height: 24px;
                    font-size: 12px;
                    //padding: 3px 10px;
                    //background: rgb(113, 69, 231);
                    border-radius: 12.5px;
                    color: #424242;
                    //border: solid #94D6DA 1px ;
                    background: #FBD13D;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .alreadyFocus{
                    width: 60px;
                    height: 24px;
                    font-size: 12px;
                    //padding: 3px 12px;
                    background: #FAFAFA;
                    border-radius: 12.5px;
                    color: #CCCCCC;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //border: solid #94D6DA 2px ;
                }
            }
        }
    }
</style>